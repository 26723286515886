<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="园区总览" name="one">
        <div class="overview_cont">
          <!-- 园区概况卡片 -->
          <el-card class="park">
            <div class="park_title">
              <h2>园区概况</h2>
            </div>
            <!-- 修改数据 -->
            <el-form :model="bjwxzzc_overview" ref="FormCont1" label-width="95px" size="small " class="parkFrom">
              <el-form-item label="已出租面积:" prop="has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '已出租面积必须为数字值'}]">
                <el-input type="number" v-model.number="bjwxzzc_overview.has_rented_area" autocomplete="off"></el-input>
                <span> ㎡</span>
              </el-form-item>
              <el-form-item label="未出租面积:" prop="not_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '未出租面积必须为数字值'}]">
                <el-input type="number" v-model.number="bjwxzzc_overview.not_rented_area" autocomplete="off"></el-input>
                <span> ㎡</span>
              </el-form-item>
              <el-form-item label="出租率:">
                <el-input type="number" :disabled="true" v-model.number="areaChange_selling_rate" autocomplete="off"></el-input>
                <span> %</span>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>最近七天人员进出情况</h2>
            </div>
            <el-table :data="all_table_show.personnel_access" border style="width: 80%;margin: 20px 0" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo['personnel_access'].pageNum - 1) * queryInfo['personnel_access'].pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="employee_nm" label="员工（人次）" align="center"></el-table-column>
              <el-table-column prop="guest_nm" label="陌生人（人次）" align="center"></el-table-column>
              <el-table-column prop="updated_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="(size)=>{handleSizeChange(size,'personnel_access')}"
              @current-change="(page)=>{handleCurrentChange(page,'personnel_access')}"
              :current-page="queryInfo['personnel_access'].pageNum"
              :page-size="queryInfo['personnel_access'].pageSize"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all_table.personnel_access.length">
            </el-pagination>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>园区能耗使用情况</h2>
            </div>
            <el-table :data="all_table_show.energy_used" border style="width: 80%;margin: 20px 0" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo['energy_used'].pageNum - 1) * queryInfo['energy_used'].pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="power" label="用电量（kw·h）" align="center"></el-table-column>
              <el-table-column prop="water" label="用水量（吨）" align="center"></el-table-column>
              <el-table-column prop="updated_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="(size)=>{handleSizeChange(size,'energy_used')}"
              @current-change="(page)=>{handleCurrentChange(page,'energy_used')}"
              :current-page="queryInfo['energy_used'].pageNum"
              :page-size="queryInfo['energy_used'].pageSize"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all_table.energy_used.length">
            </el-pagination>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>最近七天食堂消费情况</h2>
            </div>
            <el-table :data="all_table_show.canteen_consume" border style="width: 80%;margin: 20px 0" class="table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo['energy_used'].pageNum - 1) * queryInfo['canteen_consume'].pageSize + 1 + scope.$index"></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="日期" align="center"></el-table-column>
              <el-table-column prop="price" label="消费金额（元）" align="center"></el-table-column>
              <el-table-column prop="people_nm" label="消费人数" align="center"></el-table-column>
              <el-table-column prop="updated_at" label="更新时间" align="center"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @size-change="(size)=>{handleSizeChange(size,'canteen_consume')}"
              @current-change="(page)=>{handleCurrentChange(page,'canteen_consume')}"
              :current-page="queryInfo['canteen_consume'].pageNum"
              :page-size="queryInfo['canteen_consume'].pageSize"
              :page-sizes="[1, 5, 10, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all_table.canteen_consume.length">
            </el-pagination>
          </el-card>
          <div class="save_btn">
            <el-button type="primary" @click="submitForm">保存</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="园区运营" name="two">
        <div class="overview_cont">
          <el-card class="park">
            <div class="park_title">
              <h2>园区出租情况</h2>
            </div>
            <el-form :model="bjwxzzc_overview" ref="FormCont2" label-width="100px" size="small " class="enterpriseFrom rowClass">
              <h4>产业租赁</h4>
              <el-row>
                <el-form-item label="已出租面积:" prop="industry_has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '已出租面积必须为数字值'}]">
                  <el-input type="number" v-model.number="bjwxzzc_overview.industry_has_rented_area" autocomplete="off"></el-input>
                  <span> ㎡</span>
                </el-form-item>
                <el-form-item label="未出租面积:" prop="industry_not_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '未出租面积必须为数字值'}]">
                  <el-input type="number" v-model.number="bjwxzzc_overview.industry_not_rented_area" autocomplete="off"></el-input>
                  <span> ㎡</span>
                </el-form-item>
                <el-form-item label="出租率:">
                  <el-input type="number" :disabled="true" v-model.number="industry_empty_rate" autocomplete="off"></el-input>
                  <span> %</span>
                </el-form-item>
              </el-row>
              <h4>商业租赁</h4>
              <el-row>
                <el-form-item label="已出租面积:" prop="business_has_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '已出租面积必须为数字值'}]">
                  <el-input type="number" v-model.number="bjwxzzc_overview.business_has_rented_area" autocomplete="off"></el-input>
                  <span> ㎡</span>
                </el-form-item>
                <el-form-item label="未出租面积:" prop="business_not_rented_area" :rules="[{ trigger: 'blur', type: 'number', message: '未出租面积必须为数字值'}]">
                  <el-input type="number" v-model.number="bjwxzzc_overview.business_not_rented_area" autocomplete="off"></el-input>
                  <span> ㎡</span>
                </el-form-item>
                <el-form-item label="出租率:">
                  <el-input type="number" :disabled="true" v-model.number="business_empty_rate" autocomplete="off"></el-input>
                  <span> %</span>
                </el-form-item>
              </el-row>
            </el-form>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>空置房源情况</h2>
            </div>
            <div class="operTitle_cont">
              <span>类型</span><span>楼栋</span><span>房号</span><span>可租赁面积(㎡)</span>
            </div>
            <el-form ref="operateForm" v-for="(item, index) in vacant_housing" :key="index + '_1'" :model="item" label-position="top" size="small" label-width="80px" class="operateForm">
              <div class="oper_input">
                <el-input :class="item.type == '' ? 'red_error' : ''" @input="changeVacantHousing(index, item.id)" v-model="item.type" size="small"></el-input>
              </div>
              <div class="oper_input">
                <el-input :class="item.build_nm == '' ? 'red_error' : ''" @input="changeVacantHousing(index, item.id)" v-model="item.build_nm" size="small"></el-input>
              </div>
              <div class="oper_input">
                <el-input :class="item.room_nm == '' ? 'red_error' : ''" @input="changeVacantHousing(index, item.id)" v-model="item.room_nm" size="small"></el-input>
              </div>
              <div class="oper_input">
                <el-input :class="item.has_rented_area == '' ? 'red_error' : ''" @input="changeVacantHousing(index, item.id)" v-model="item.has_rented_area" size="small"></el-input>
              </div>
              <div class="form_btn">
                <el-button v-if="index >= 1" class="numberInput"  @click="removeVacantHousing(item.id)" type="text" icon="el-icon-minus"></el-button>
                <el-button class="numberInput"  @click="addVacantHousing()" type="text" icon="el-icon-plus"></el-button>
              </div>
            </el-form>
          </el-card>
          <el-card class="enterprise">
            <div class="enterprise_title">
              <h2>入驻商业</h2>
            </div>
            <el-form :model="joining_business_data" ref="form_joining_business" label-width="95px" size="small " class="healthyFrom">
              <el-form-item label="商业类型:" prop="business_type" :rules="[{ type: 'string', required: true, message: '请输入商业类型', trigger: 'blur' }]">
                <el-input v-model="joining_business_data.business_type" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="商家数量:" prop="business_nm" :rules="[{ type: 'number', required: true, message: '请输入商商家数量'}]">
                <el-input type="number" v-model.number="joining_business_data.business_nm" autocomplete="off"></el-input>
              </el-form-item>
              <el-button type="primary" @click="addJoiningBusiness">增加</el-button>
            </el-form>
            <div class="tag_cont">
              <el-tag v-for="(item, index) in joining_business" :key="index"  class="tagItem" closable type="info" @close="removeJoiningBusiness(item.id)">{{item.business_type}} {{item.business_nm}}</el-tag>
            </div>
          </el-card>
          <!-- 商户概况 -->
          <el-card class="enterprise facilities">
            <div class="enterprise_title">
              <h2>商户概况</h2>
            </div>
            <el-button type="primary" class="facilities_btn" @click="dialogVisible = true">新增商户概况</el-button>
            <el-table :data="business_overview" border style="width: 80%" class="facilities_table">
              <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
              <el-table-column prop="image" label="封面" align="center">
                <template slot-scope="scope">
                  <el-image :src="serverAddress + scope.row.image" fit="fill"></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="type" label="类型" align="center">
                <template>
                  商业
                </template>
              </el-table-column>
              <el-table-column prop="name" label="商户名称" align="center"></el-table-column>
              <el-table-column prop="company_name" label="企业名称" align="center"></el-table-column>
              <el-table-column prop="profile" label="商户简介" align="center"></el-table-column>
              <el-table-column prop="order_num" label="排序号" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="showEditDialog(scope.row.id)">编辑</el-button>
                  <el-button type="text"  @click="removeBusinessOverview(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
          <el-dialog class="attract_cont" title="新增商户概况" :visible.sync="dialogVisible" :closeOnClickModal="false" :center="true" width="50%">
            <!-- 内容主体区 -->
            <el-form id='addFormRef' ref="addFormRef" :model="business_overview_data" :rules="addRules"  label-width="100px">
              <el-form-item label="封面：" prop="image">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChange"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="business_overview_data.image" :src="business_overview_data.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="商户名称:" prop="name">
                <el-input placeholder="输入商户名称" name='name' v-model="business_overview_data.name"></el-input>
              </el-form-item>
              <el-form-item label="企业名称:" prop="company_name">
                <el-input placeholder="输入企业名称" name='company_name' v-model="business_overview_data.company_name"></el-input>
              </el-form-item>
              <el-form-item label="商户简介:" prop="profile">
                <el-input type="textarea" placeholder="输入商户简介" name='profile' v-model="business_overview_data.profile"></el-input>
              </el-form-item>
              <el-form-item label="排序号:" prop="order_num">
                <el-input placeholder="输入排序号" type="number" name='order_num' v-model.number="business_overview_data.order_num"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="addBusinessOverview">确 定</el-button>
            </span>
          </el-dialog>
          <el-dialog class="attract_cont" title="编辑商户概况" :visible.sync="dialogVisibleEdit" :closeOnClickModal="false" :center="true" width="50%">
            <el-form id='addFormRefEdit' :model="get_business_overview" :rules="addRules"  label-width="100px">
              <el-form-item label="封面：" prop="image">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChangeEdit"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="get_business_overview.image" :src="get_business_overview.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="商户名称:" prop="name">
                <el-input placeholder="输入商户名称" name='name' v-model="get_business_overview.name"></el-input>
              </el-form-item>
              <el-form-item label="企业名称:" prop="company_name">
                <el-input placeholder="输入企业名称" name='company_name' v-model="get_business_overview.company_name"></el-input>
              </el-form-item>
              <el-form-item label="商户简介:" prop="profile">
                <el-input type="textarea" placeholder="输入商户简介" name='profile' v-model="get_business_overview.profile"></el-input>
              </el-form-item>
              <el-form-item label="排序号:" prop="order_num">
                <el-input placeholder="输入排序号" type="number" name='order_num' v-model.number="get_business_overview.order_num"></el-input>
              </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleEdit = false">取 消</el-button>
              <el-button type="primary" @click="editBusinessOverview">确 定</el-button>
            </span>
          </el-dialog>

          <!-- 入驻产业 -->
          <el-card class="enterprise facilities">
            <div class="enterprise_title">
              <h2>入驻产业</h2>
            </div>
            <el-button type="primary" class="facilities_btn" @click="dialogVisible4 = true">新增入驻产业</el-button>
            <el-table :data="joining_company" border style="width: 80%" class="facilities_table">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <div v-text="(queryInfo.jc_page - 1) * 10 + scope.$index + 1"></div>
                </template>
              </el-table-column>
              <el-table-column prop="image" label="封面" align="center">
                <template slot-scope="scope">
                  <el-image :src="serverAddress + scope.row.image" fit="fill"></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="type" label="类型" align="center">
                <template>
                  企业
                </template>
              </el-table-column>
              <el-table-column prop="name" label="企业名称" align="center"></el-table-column>
              <el-table-column prop="profile" label="企业简介" align="center"></el-table-column>
              <el-table-column prop="order_num" label="排序" align="center">
                <template slot-scope="scope">
                  {{ scope.row.order_num ? scope.row.order_num : 0 }}
                </template>
              </el-table-column>
              <el-table-column prop="created_at" label="创建时间" align="center">
                <template slot-scope="scope">
                  {{new Date(scope.row.created_at).getFullYear() + '-' + (new Date(scope.row.created_at).getMonth()+1) + '-' + new Date(scope.row.created_at).getDate()}}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="showEditDialog4(scope.row.id)">编辑</el-button>
                  <el-button type="text"  @click="removeJoiningCompany(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              @current-change="jc_page_change"
              :current-page="queryInfo.jc_page"
              :total="queryInfo.jc_total">
            </el-pagination>
          </el-card>
          <el-dialog class="attract_cont" title="新增入驻产业" :visible.sync="dialogVisible4" :closeOnClickModal="false" :center="true" width="50%">
            <!-- 内容主体区 -->
            <el-form id='addFormRef4' ref="addFormRef4" :model="joining_company_data" :rules="addRules4"  label-width="100px">
              <el-form-item label="封面：" prop="image">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChange4"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="joining_company_data.image" :src="joining_company_data.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="企业名称:" prop="name">
                <el-input placeholder="输入企业名称" name='name' v-model="joining_company_data.name"></el-input>
              </el-form-item>
              <el-form-item label="企业简介:" prop="profile">
                <el-input type="textarea" placeholder="输入企业简介" name='profile' v-model="joining_company_data.profile"></el-input>
              </el-form-item>
              <el-form-item label="排序:" prop="order_num">
                <el-input placeholder="输入排序号" name='order_num' v-model="joining_company_data.order_num"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible4 = false">取 消</el-button>
              <el-button type="primary" @click="addJoiningCompany">确 定</el-button>
            </span>
          </el-dialog>
          <el-dialog class="attract_cont" title="编辑入驻产业" :visible.sync="dialogVisibleEdit4" :closeOnClickModal="false" :center="true" width="50%">
            <el-form id='addFormRefEdit4' :model="get_joining_company" :rules="addRules4"  label-width="100px">
              <el-form-item label="封面：" prop="image">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChangeEdit4"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="get_joining_company.image" :src="get_joining_company.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="企业名称:" prop="name">
                <el-input placeholder="输入企业名称" name='name' v-model="get_joining_company.name"></el-input>
              </el-form-item>
              <el-form-item label="企业简介:" prop="profile">
                <el-input type="textarea" placeholder="输入企业简介" name='profile' v-model="get_joining_company.profile"></el-input>
              </el-form-item>
              <el-form-item label="排序:" prop="order_num">
                <el-input placeholder="输入排序号" name='order_num' v-model="get_joining_company.order_num"></el-input>
              </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleEdit4 = false">取 消</el-button>
              <el-button type="primary" @click="editJoiningCompany">确 定</el-button>
            </span>
          </el-dialog>

          <div class="save_btn">
            <el-button type="primary" @click="submitForm">保存</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="园区招商" name="three">
        <div class="overview_cont">
          <!-- 园区荣誉 -->
          <el-card class="enterprise facilities">
            <div class="enterprise_title">
              <h2>园区荣誉</h2>
            </div>
            <el-button type="primary" class="facilities_btn" @click="dialogVisible2 = true">新增园区荣誉</el-button>
            <el-table :data="park_honor" border style="width: 80%" class="facilities_table">
              <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
              <el-table-column prop="image" label="荣誉证书" align="center">
                <template slot-scope="scope">
                  <el-image :src="serverAddress + scope.row.image" fit="fill"></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="title" label="荣誉名称" align="center"></el-table-column>
              <el-table-column prop="date" label="获得日期" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="showEditDialog2(scope.row.id)">编辑</el-button>
                  <el-button type="text"  @click="removeParkHonor(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
          <el-dialog class="attract_cont" title="新增园区荣誉" :visible.sync="dialogVisible2" :closeOnClickModal="false" :center="true" width="50%">
            <!-- 内容主体区 -->
            <el-form id='addFormRef2' ref="addFormRef2" :model="park_honor_data" :rules="addRules2"  label-width="100px">
              <el-form-item label="荣誉证书：" prop="image">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChange2"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="park_honor_data.image" :src="park_honor_data.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="荣誉名称:" prop="title">
                <el-input placeholder="输入荣誉名称" name='title' v-model="park_honor_data.title"></el-input>
              </el-form-item>
              <el-form-item label="获得日期:" prop="date">
                <el-date-picker v-model="park_honor_data.date" name="date" type="date" placeholder="请选择获得日期" />
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible2 = false">取 消</el-button>
              <el-button type="primary" @click="addParkHonor">确 定</el-button>
            </span>
          </el-dialog>
          <el-dialog class="attract_cont" title="编辑园区荣誉" :visible.sync="dialogVisibleEdit2" :closeOnClickModal="false" :center="true" width="50%">
            <el-form id='addFormRefEdit2' :model="get_park_honor" :rules="addRules2"  label-width="100px">
              <el-form-item label="封面：" prop="image">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChangeEdit2"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="get_park_honor.image" :src="get_park_honor.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="荣誉名称:" prop="title">
                <el-input placeholder="输入荣誉名称" name='title' v-model="get_park_honor.title"></el-input>
              </el-form-item>
              <el-form-item label="获得日期:" prop="date">
                <el-date-picker v-model="get_park_honor.date" name="date" type="date" placeholder="请选择获得日期" />
              </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleEdit2 = false">取 消</el-button>
              <el-button type="primary" @click="editParkHonor">确 定</el-button>
            </span>
          </el-dialog>
          <el-card class="park">
            <div class="park_title">
              <h2>园区报导</h2>
            </div>
            <div class="operTitle_cont2">
              <span>报导媒体</span><span>报导次数</span>
            </div>
            <el-form ref="operateForm2" v-for="(item, index) in park_report" :key="index + '_1'" :model="item" label-position="top" size="small" label-width="80px" class="operateForm">
              <div class="oper_input">
                <el-input :class="item.media == '' ? 'red_error' : ''" @input="changeParkReport(index, item.id)" v-model="item.media" size="small"></el-input>
              </div>
              <div class="oper_input">
                <el-input :class="item.nm == '' ? 'red_error' : ''" @input="changeParkReport(index, item.id)" v-model="item.nm" size="small"></el-input>
              </div>
              <div class="form_btn">
                <el-button v-if="index >= 1" class="numberInput"  @click="removeParkReport(item.id)" type="text" icon="el-icon-minus"></el-button>
                <el-button class="numberInput"  @click="addParkReport()" type="text" icon="el-icon-plus"></el-button>
              </div>
            </el-form>
          </el-card>
          <el-card class="park">
            <div class="park_title">
              <h2>园区活动</h2>
            </div>
            <el-form :model="bjwxzzc_overview" ref="FormCont3" label-width="100px" size="small " class="enterpriseFrom rowClass">
              <el-row>
                <el-form-item label="活动数量:" prop="activities_nm" :rules="[{ trigger: 'blur', type: 'number', message: '活动数量必须为数字值'}]">
                  <el-input type="number" v-model.number="bjwxzzc_overview.activities_nm" autocomplete="off"></el-input>
                  <span> 场</span>
                </el-form-item>
                <el-form-item label="参与人数:" prop="activities_people_nm" :rules="[{ trigger: 'blur', type: 'number', message: '参与人数必须为数字值'}]">
                  <el-input type="number" v-model.number="bjwxzzc_overview.activities_people_nm" autocomplete="off"></el-input>
                  <span> 人</span>
                </el-form-item>
              </el-row>
            </el-form>
            <el-button type="primary" class="facilities_btn" @click="dialogVisible3 = true">新增园区活动</el-button>
            <el-table :data="park_activity" border style="width: 80%" class="facilities_table">
              <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
              <el-table-column prop="image" label="活动封面" align="center">
                <template slot-scope="scope">
                  <el-image :src="serverAddress + scope.row.image" lazy fit="fill"></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="title" label="活动名称" align="center"></el-table-column>
              <el-table-column prop="profile" label="活动简介" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="showEditDialog3(scope.row.id)">编辑</el-button>
                  <el-button type="text"  @click="removeParkActivity(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
          <el-dialog class="attract_cont" title="新增园区活动" :visible.sync="dialogVisible3" :closeOnClickModal="false" :center="true" width="50%">
            <!-- 内容主体区 -->
            <el-form id='addFormRef3' ref="addFormRef3" :model="park_activity_data" :rules="addRules3"  label-width="100px">
              <el-form-item label="活动封面：" prop="image">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChange3"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="park_activity_data.image" :src="park_activity_data.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="活动名称:" prop="title">
                <el-input placeholder="输入活动名称" name='title' v-model="park_activity_data.title"></el-input>
              </el-form-item>
              <el-form-item label="活动简介:" prop="profile">
                <el-input type="textarea" placeholder="输入活动简介" name='profile' v-model="park_activity_data.profile"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible3 = false">取 消</el-button>
              <el-button type="primary" @click="addParkActivity">确 定</el-button>
            </span>
          </el-dialog>
          <el-dialog class="attract_cont" title="编辑园区活动" :visible.sync="dialogVisibleEdit3" :closeOnClickModal="false" :center="true" width="50%">
            <el-form id='addFormRefEdit3' :model="get_park_activity" :rules="addRules3"  label-width="100px">
              <el-form-item label="活动封面：" prop="image">
                <el-upload
                  class="avatar-uploader"
                  action=""
                  accept="image/jpeg,image/gif,image/png"
                  name="image"
                  :on-change="onUploadChangeEdit3"
                  :auto-upload="false"
                  :show-file-list="false">
                  <img v-if="get_park_activity.image" :src="get_park_activity.image" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="活动名称:" prop="title">
                <el-input placeholder="输入活动名称" name='title' v-model="get_park_activity.title"></el-input>
              </el-form-item>
              <el-form-item label="活动简介:" prop="profile">
                <el-input type="textarea" placeholder="输入活动简介" name='profile' v-model="get_park_activity.profile"></el-input>
              </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleEdit2 = false">取 消</el-button>
              <el-button type="primary" @click="editParkActivity">确 定</el-button>
            </span>
          </el-dialog>
          <div class="save_btn">
            <el-button type="primary" @click="submitForm">保存</el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serverAddress: 'https://res.bjstarfish.com/',
      // 园区概况的数据
      bjwxzzc_overview: {},
      vacant_housing: [],
      park_report: [],
      changeVacantHousingTimer: [],
      changeParkReportTimer: [],
      business_overview: [],
      joining_business: [],
      joining_company: [],
      park_activity: [],
      park_honor: [],
      dialogVisible: false,
      dialogVisibleEdit: false,
      dialogVisible2: false,
      dialogVisibleEdit2: false,
      dialogVisible3: false,
      dialogVisibleEdit3: false,
      dialogVisible4: false,
      dialogVisibleEdit4: false,
      business_overview_data: {
        image: '',
        name: '',
        company_name: '',
        profile: ''
      },
      joining_company_data: {
        image: '',
        name: '',
        profile: '',
        order_num: ''
      },
      park_honor_data: {
        image: '',
        title: '',
        date: ''
      },
      park_activity_data: {
        image: '',
        title: '',
        profile: ''
      },
      get_park_honor: {
        image: '',
        title: '',
        date: ''
      },
      get_park_activity: {
        image: '',
        title: '',
        profile: ''
      },
      get_business_overview: {
        image: '',
        name: '',
        company_name: '',
        profile: ''
      },
      get_joining_company: {
        image: '',
        name: '',
        profile: '',
        order_num: ''
      },
      vacant_housing_data: {
        housing_name: '',
        housing_area: ''
      },
      get_vacant_housing: {
        logo: '',
        name: ''
      },
      joining_business_data: {
        business_type: '',
        business_nm: ''
      },
      get_joining_business: {
        logo: '',
        name: ''
      },
      all_table: {
        energy_used: [],
        charging_used: [],
        mettingroom_used: [],
        personnel_access: [],
        car_access: [],
        real_violations: [],
        canteen_consume: []
      },
      all_table_show: {
        energy_used: [],
        charging_used: [],
        mettingroom_used: [],
        personnel_access: [],
        car_access: [],
        real_violations: [],
        canteen_consume: []
      },
      addRules: {
        name: [
          { required: true, message: '请输入商户名称', trigger: 'blur' }
        ],
        company_name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '请选取封面', trigger: 'blur' }
        ],
        profile: [
          { required: true, message: '请输入商户简介', trigger: 'blur' }
        ]
      },
      addRules2: {
        title: [
          { required: true, message: '请输入荣誉名称', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '请选取荣誉证书', trigger: 'blur' }
        ],
        date: [
          { required: true, message: '请选择获得日期', trigger: 'blur' }
        ]
      },
      addRules3: {
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '请选取活动封面', trigger: 'blur' }
        ],
        profile: [
          { required: true, message: '请输入活动介绍', trigger: 'blur' }
        ]
      },
      addRules4: {
        name: [
          { required: true, message: '请输入商户名称', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '请选取封面', trigger: 'blur' }
        ],
        profile: [
          { required: true, message: '请输入商户简介', trigger: 'blur' }
        ]
      },
      // 选择到第几个模块 first/second
      activeName: 'one',
      queryInfo: {
        energy_used: {
          pageNum: 1,
          pageSize: 10
        },
        personnel_access: {
          pageNum: 1,
          pageSize: 10
        },
        canteen_consume: {
          pageNum: 1,
          pageSize: 10
        },
        jc_page: 1,
        jc_total: 0
      }
    }
  },
  computed: {
    areaChange_selling_rate () {
      return Math.round(this.bjwxzzc_overview.has_rented_area / (this.bjwxzzc_overview.has_rented_area * 1 + this.bjwxzzc_overview.not_rented_area * 1) * 100)
    },
    industry_empty_rate () {
      return Math.round(this.bjwxzzc_overview.industry_has_rented_area / (this.bjwxzzc_overview.industry_has_rented_area * 1 + this.bjwxzzc_overview.industry_not_rented_area * 1) * 100)
    },
    business_empty_rate () {
      return Math.round(this.bjwxzzc_overview.business_has_rented_area / (this.bjwxzzc_overview.business_has_rented_area * 1 + this.bjwxzzc_overview.business_not_rented_area * 1) * 100)
    }
  },
  async created () {
    // 获取园区概况的数据
    const { data: res } = await this.$http.get('bjwxzzc')
    this.bjwxzzc_overview = res.data
    this.getVacantHousingList()
    this.getJoiningBusinessList()
    this.getJoiningCompanyList()
    this.getBusinessOverviewList()
    this.getParkHonorList()
    this.getParkReportList()
    this.getParkActivityList()
    this.getAllTable()
  },
  methods: {
    async getAllTable () {
      const { data: res } = await this.$http.get('bjwxzzc_all_table')
      this.all_table = res.data
      Object.keys(this.all_table).forEach(key => {
        this.all_table_show[key] = this.all_table[key].slice(0, 10)
      })
    },
    // 保存数据
    async submitForm () {
      const res = await this.$http.post('bjwxzzc_update', {
        has_rented_area: this.bjwxzzc_overview.has_rented_area,
        not_rented_area: this.bjwxzzc_overview.not_rented_area,
        industry_has_rented_area: this.bjwxzzc_overview.industry_has_rented_area,
        industry_not_rented_area: this.bjwxzzc_overview.industry_not_rented_area,
        business_has_rented_area: this.bjwxzzc_overview.business_has_rented_area,
        business_not_rented_area: this.bjwxzzc_overview.business_not_rented_area,
        activities_nm: this.bjwxzzc_overview.activities_nm,
        activities_people_nm: this.bjwxzzc_overview.activities_people_nm
      })
      if (res) this.$message({ message: '保存成功', type: 'success' })
    },

    async getParkHonorList () {
      const { data: res } = await this.$http.get('bjwxzzc_park_honor')
      this.park_honor = res.data
    },

    async getParkActivityList () {
      const { data: res } = await this.$http.get('bjwxzzc_park_activity')
      this.park_activity = res.data
    },

    onUploadChange (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.business_overview_data.image = e.currentTarget.result
      }
    },
    onUploadChange2 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.park_honor_data.image = e.currentTarget.result
      }
    },
    onUploadChange3 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.park_activity_data.image = e.currentTarget.result
      }
    },
    onUploadChange4 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.joining_company_data.image = e.currentTarget.result
      }
    },
    onUploadChangeEdit (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.get_business_overview.image = e.currentTarget.result
      }
    },
    onUploadChangeEdit2 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.get_park_honor.image = e.currentTarget.result
      }
    },
    onUploadChangeEdit3 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.get_park_activity.image = e.currentTarget.result
      }
    },
    onUploadChangeEdit4 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.get_joining_company.image = e.currentTarget.result
      }
    },
    async getBusinessOverviewList () {
      const { data: res } = await this.$http.get('bjwxzzc_business_overview')
      this.business_overview = res.data
    },
    async getJoiningCompanyList () {
      const { data: res } = await this.$http.get(`bjwxzzc_joining_company?limit=10&page=${this.queryInfo.jc_page}`)
      this.joining_company = res.data
      this.queryInfo.jc_page = res.pagination.page
      this.queryInfo.jc_total = res.pagination.all_count
    },
    jc_page_change (newPage) {
      this.queryInfo.jc_page = newPage
      this.getJoiningCompanyList()
    },
    async getVacantHousingList () {
      const { data: res } = await this.$http.get('bjwxzzc_vacant_housing')
      this.vacant_housing = res.data
    },
    async getJoiningBusinessList () {
      const { data: res } = await this.$http.get('bjwxzzc_joining_business')
      this.joining_business = res.data
    },
    async getParkReportList () {
      const { data: res } = await this.$http.get('bjwxzzc_park_report')
      this.park_report = res.data
    },
    addBusinessOverview () {
      const data = new FormData(document.getElementById('addFormRef'))
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('bjwxzzc_business_overview', data)
        this.dialogVisible = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getBusinessOverviewList()
      })
    },
    addJoiningCompany () {
      const data = new FormData(document.getElementById('addFormRef4'))
      this.$refs.addFormRef4.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('bjwxzzc_joining_company', data)
        this.dialogVisible4 = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getJoiningCompanyList()
      })
    },
    addParkHonor () {
      const data = new FormData(document.getElementById('addFormRef2'))
      this.$refs.addFormRef2.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('bjwxzzc_park_honor', data)
        this.dialogVisible2 = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getParkHonorList()
      })
    },
    addParkActivity () {
      const data = new FormData(document.getElementById('addFormRef3'))
      this.$refs.addFormRef3.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('bjwxzzc_park_activity', data)
        this.dialogVisible3 = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getParkActivityList()
      })
    },
    async addVacantHousing () {
      const { data: res } = await this.$http.post('bjwxzzc_vacant_housing')
      this.vacant_housing.push(res.data)
      if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
    },
    async addParkReport () {
      const { data: res } = await this.$http.post('bjwxzzc_park_report')
      this.park_report.push(res.data)
      if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
    },
    async addJoiningBusiness () {
      this.$refs.form_joining_business.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('bjwxzzc_joining_business', {
          business_type: this.joining_business_data.business_type,
          business_nm: this.joining_business_data.business_nm
        })
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getJoiningBusinessList()
      })
    },
    async editBusinessOverview () {
      const data = new FormData(document.getElementById('addFormRefEdit'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`bjwxzzc_business_overview/${this.get_business_overview.id}`, data)
      this.dialogVisibleEdit = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getBusinessOverviewList()
    },
    async editJoiningCompany () {
      const data = new FormData(document.getElementById('addFormRefEdit4'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`bjwxzzc_joining_company/${this.get_joining_company.id}`, data)
      this.dialogVisibleEdit4 = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getJoiningCompanyList()
    },
    async editParkHonor () {
      const data = new FormData(document.getElementById('addFormRefEdit2'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`bjwxzzc_park_honor/${this.get_park_honor.id}`, data)
      this.dialogVisibleEdit2 = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getParkHonorList()
    },
    async editParkActivity () {
      const data = new FormData(document.getElementById('addFormRefEdit3'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`bjwxzzc_park_activity/${this.get_park_activity.id}`, data)
      this.dialogVisibleEdit3 = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getParkActivityList()
    },
    async showEditDialog (id) {
      this.dialogVisibleEdit = true
      const { data: res } = await this.$http.get(`bjwxzzc_business_overview/${id}`)
      this.get_business_overview = res.data
      this.get_business_overview.image = this.serverAddress + res.data.image
    },
    async showEditDialog2 (id) {
      this.dialogVisibleEdit2 = true
      const { data: res } = await this.$http.get(`bjwxzzc_park_honor/${id}`)
      this.get_park_honor = res.data
      this.get_park_honor.image = this.serverAddress + res.data.image
    },
    async showEditDialog3 (id) {
      this.dialogVisibleEdit3 = true
      const { data: res } = await this.$http.get(`bjwxzzc_park_activity/${id}`)
      this.get_park_activity = res.data
      this.get_park_activity.image = this.serverAddress + res.data.image
    },
    async showEditDialog4 (id) {
      this.dialogVisibleEdit4 = true
      const { data: res } = await this.$http.get(`bjwxzzc_joining_company/${id}`)
      this.get_joining_company = res.data
      this.get_joining_company.image = this.serverAddress + res.data.image
    },
    async removeBusinessOverview (id) {
      const { data: res } = await this.$http.delete(`bjwxzzc_business_overview/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getBusinessOverviewList()
    },
    async removeJoiningCompany (id) {
      const { data: res } = await this.$http.delete(`bjwxzzc_joining_company/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getJoiningCompanyList()
    },
    async removeVacantHousing (id) {
      const { data: res } = await this.$http.delete(`bjwxzzc_vacant_housing/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getVacantHousingList()
    },
    async removeParkReport (id) {
      const { data: res } = await this.$http.delete(`bjwxzzc_park_report/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getParkReportList()
    },
    async removeParkActivity (id) {
      const { data: res } = await this.$http.delete(`bjwxzzc_park_activity/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getParkActivityList()
    },
    async changeVacantHousing (key, id) {
      if (this.changeVacantHousingTimer[key]) {
        clearTimeout(this.changeVacantHousingTimer[key])
        this.changeVacantHousingTimer[key] = null
      }
      this.changeVacantHousingTimer[key] = setTimeout(async () => {
        const { data: res } = await this.$http.post(`bjwxzzc_vacant_housing/${id}`, {
          build_nm: this.vacant_housing[key].build_nm,
          type: this.vacant_housing[key].type,
          room_nm: this.vacant_housing[key].room_nm,
          has_rented_area: this.vacant_housing[key].has_rented_area,
          _method: 'PUT'
        })
        if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      }, 1000)
    },
    async changeParkReport (key, id) {
      if (this.changeParkReportTimer[key]) {
        clearTimeout(this.changeParkReportTimer[key])
        this.changeParkReportTimer[key] = null
      }
      this.changeParkReportTimer[key] = setTimeout(async () => {
        const { data: res } = await this.$http.post(`bjwxzzc_park_report/${id}`, {
          media: this.park_report[key].media,
          nm: this.park_report[key].nm,
          _method: 'PUT'
        })
        if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      }, 1000)
    },
    async removeJoiningBusiness (id) {
      const { data: res } = await this.$http.delete(`bjwxzzc_joining_business/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getJoiningBusinessList()
    },
    async removeParkHonor (id) {
      const { data: res } = await this.$http.delete(`bjwxzzc_park_honor/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getParkHonorList()
    },
    // 选项卡切换
    handleClick (tab, event) {
      // console.log(tab, event)
    },
    handleSizeChange (newSize, key) {
      this.queryInfo[key].pageSize = newSize
      this.queryInfo[key].pageNum = 1
      this.all_table_show[key] = this.all_table[key].slice(0, this.queryInfo[key].pageSize)
    },
    handleCurrentChange (newPage, key) {
      this.queryInfo[key].pageNum = newPage
      const start = (this.queryInfo[key].pageNum - 1) * this.queryInfo[key].pageSize
      const end = this.queryInfo[key].pageNum * this.queryInfo[key].pageSize
      this.all_table_show[key] = this.all_table[key].slice(start, end)
    }
  }
}
</script>

<style lang="less" scoped>
.overview_cont{
  padding: 1rem;
  .park,.enterprise{
    margin-bottom: 1rem;
    .park_title,.enterprise_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .rowClass{
      flex-direction: column;
    }
    .el-row{
      display: flex;
      width: 100%;
    }
    .parkFrom,.enterpriseFrom{
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;
      .el-form-item{
        width: 20%;
        .el-input{
          width: 75%;
        }
      }
    }
  }
  .save_btn{
    display: flex;
    justify-content: center;
    .el-button--primary{
      background-color: #015478;
      border-color: #015478;
      padding: 12px 60px;
    }
  }
}
.situation_cont{
  .situation{
    margin-bottom: 1rem;
    .title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 1px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .table{
      margin: 1rem 0;
    }
  }
}
.el-textarea{
  margin-top: 20px;
  width: 80%;
}
.facilities_btn{
  margin: 1rem;
}
.operTitle_cont2{
  width: 23%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    width: 23%;
    font-size: 14px;
    color: #606266;
    text-align: center;
    padding: 0.5rem 0;
  }
}
.operTitle_cont{
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    width: 23%;
    font-size: 14px;
    color: #606266;
    text-align: center;
    padding: 0.5rem 0;
  }
}
.operateForm{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-form-item,.oper_input{
    width: 20%;
    text-align: center;
  }
  .form_btn{
    width: 10%;
    display: flex;
    font-size: 1.25rem;
  }
}
.healthyFrom{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  .el-form-item{
    width: 30%;
    text-align: center;
    .el-input{
      width: 100%;
    }
  }
  .el-button{
    width: 10%;
    background-color: #015478;
    border-color: #015478;
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 5px;
    align-self: flex-start;
  }
}
.tag_cont{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 7rem;
  .tagItem{
    width: 10%;
    text-align: center;
    height: 46px;
    border: none;
    line-height: 46px;
    margin-right: 1rem;
  }
}
</style>
